import amber from "@mui/material/colors/amber";
import blue from "@mui/material/colors/blue";
import deepPurple from "@mui/material/colors/deepPurple";
import orange from "@mui/material/colors/orange";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import styled from "../../util/styled";
import { ServiceItemColor } from "./props";

const PREFIX = "ServiceItem";

export const classes = {
  verticalDivider: `${PREFIX}-verticalDivider`,
  gutterBottom: `${PREFIX}-gutterBottom`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  button: `${PREFIX}-button`,
};

const colors: { [key: string]: any } = {
  amber,
  deepPurple,
  orange,
  blue,
};

export const StyledGrid = styled(Grid)<{
  color: ServiceItemColor;
}>(({ theme, color }) => ({
  padding: theme.spacing(0, 3),
  margin: theme.spacing(5, 0),
  color: "initial",

  [`&.${classes.verticalDivider}`]: {
    borderRightStyle: "solid",
    borderRightColor: alpha(theme.palette.innoGrey.main, 0.2),
    borderRightWidth: 1,
  },

  [`& .${classes.gutterBottom}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.avatar}`]: {
    width: 100,
    height: 100,
    backgroundColor: colors[color][500],
  },

  [`& .${classes.icon}`]: {
    width: "55%",
    height: "55%",
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
  },

  [`& .${classes.text}`]: {
    flexGrow: 1,
  },

  [`& .${classes.button}`]: {
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: colors[color][500],
    "&:hover": {
      backgroundColor: alpha(colors[color][500], 0.15),
    },
  },
}));
