import React from "react";
import PriceCalculator from "../../../components/PriceCalculator";
import PricingSection from "../../../components/PricingSection";
import { METRONOME_PRICING_SECTION } from "../../../util/constants";
import { PriceRangeInfos } from "../../../util/types";
import Alert from "@mui/material/Alert";
import { PriceCalculatorInput } from "../../../components/PriceCalculator/tools";
import { getPriceRangeInfos } from "./fp";

const renderDetailedResult = (
  ranges: PriceRangeInfos[] | null,
  input: PriceCalculatorInput,
  classes: {
    alert: string;
    bold: string;
  },
) =>
  ranges && ranges[0] ? (
    <Alert variant="outlined" severity="info" className={classes.alert}>
      Le montant de l'abonnement annuel Innovance Métronome pour un budget de{" "}
      <span className={classes.bold}>{input.value} €</span> sera de :{" "}
      <span className={classes.bold}>{ranges[0].totalPrice} € HT</span>
    </Alert>
  ) : null;

const MetronomePricing = () => (
  <PricingSection
    product="Métronome"
    sectionId={METRONOME_PRICING_SECTION}
    priceFileName="innovance-metronome-fiche-tarifs.pdf"
    productFileName="innovance-metronome-fiche-produit.pdf"
    CalculatorComponent={
      <PriceCalculator
        fieldLabel="Budget annuel (€)"
        getPriceRangeInfos={getPriceRangeInfos}
        renderDetailedResult={renderDetailedResult}
      />
    }
  />
);

export default MetronomePricing;
