import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "BudgetFeatures";

export const classes = {
  container: `${PREFIX}-container`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  backgroundColor: "transparent",
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },
}));
