import Container from "@mui/material/Container/Container";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { METRONOME_CAROUSEL_SECTION } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const items = [
  "innovance-solutions-metronome-mockups-suivi-budget.png",
  "innovance-solutions-metronome-mockups-ecritures-compta.png",
  "innovance-solutions-metronome-mockups-modeles-rapports.png",
];

const Item = ({ fileName }: { fileName: string }) => {
  const imgSrc = "/images/metronome/" + fileName;

  return (
    <div
      style={{
        width: 1000,
        margin: "auto",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "auto",
        }}
        width={900}
        height={614}
        src={imgSrc}
        alt={fileName}
      />
    </div>
  );
};

const MetronomeCarousel = () => {
  return (
    <StyledSectionWrapper sectionId={METRONOME_CAROUSEL_SECTION}>
      <Container className={classes.container}>
        <Carousel
          navButtonsAlwaysVisible
          cycleNavigation
          animation="slide"
          autoPlay={false}
          className={classes.carousel}
          fullHeightHover={false}
          navButtonsProps={{
            className: classes.navButton,
          }}
          navButtonsWrapperProps={{
            className: classes.navWrapper,
          }}
          activeIndicatorIconButtonProps={{
            className: classes.activeIndicator,
          }}
        >
          {items.map((i) => (
            <Item key={i} fileName={i} />
          ))}
        </Carousel>
      </Container>
    </StyledSectionWrapper>
  );
};

export default MetronomeCarousel;
