import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import {
  METRONOME_FEATURE_STRUCTURE,
  METRONOME_FEATURE_MONITORING,
  METRONOME_FEATURE_CONTROL,
  METRONOME_FEATURE_REPORT,
} from "../../../util/constants";
import FeatureRow from "./FeatureRow";
import { classes, StyledSectionWrapper } from "./styles";

const Features = () => (
  <StyledSectionWrapper sectionId={METRONOME_FEATURE_STRUCTURE}>
    <Container className={classes.container}>
      <SectionTitle text="Fonctionnalités" variant="innoGrey" />
      <FeatureRow
        product="IB"
        id={METRONOME_FEATURE_STRUCTURE}
        title="Structurer son budget"
        imgSrc="/images/metronome/innovance-solutions-metronome-structurer-son-budget.svg"
        imgWidth="757"
        imgHeight="474"
        items={[
          {
            desc: "Vous créez le budget global de votre structure. Vous différenciez le budget de fonctionnement (indirecte) des budgets d'actions (analytique) et les présentez dans une seule et même interface.",
            interest:
              "Votre budget devient clair et lisible. En un simple clic vous accédez aux charges du « théâtre en ordre de marche » ou à tout autre mission de votre lieu : diffusion, production, actions culturelles…",
          },
          {
            desc: "Un budget analytique peut être délégué à un responsable de pôle/action via un droit d’accès particulier, interdisant par exemple son accès aux détails des salaires.",
            interest:
              "Vous instaurez un dialogue et une co-construction budgétaire entre le responsable d’un projet et vous-même. Vous économisez du temps en évitant une double tache. Ex : le directeur de production renseigne le budget de production du prochain opéra et suit l’avancement de la production jusqu’à son terme.",
          },
        ]}
      />
      <Divider />
      <FeatureRow
        direction="row-reverse"
        product="IB"
        id={METRONOME_FEATURE_MONITORING}
        title="Suivre son budget"
        imgSrc="/images/budget/innovance-solutions-budget-suivi-budgetaire.svg"
        imgWidth="757"
        imgHeight="474"
        items={[
          {
            desc: "Adaptez votre budget global aux temps forts de l’année : budget voté, budgets rectifiés des subventions obtenues, projections du budget réalisable en fin d’exercice, etc.",
            interest:
              "Vous restez toujours en phase avec l’activité économique de votre structure.",
          },
          {
            desc: "Alimentez vos tableaux budgétaires grâce à l’import de votre comptabilité dans Métronome (l'export-import nécessite 3 à 5 minutes de temps de votre comptable). La comparaison immédiate Prévisionnel/Réalisé vous permet de maitriser vos dépenses.",
            interest:
              "Vous disposez d’une information 100% fiable, 100% précise, pour rester serein(ne) en toute météo budgétaire.",
          },
        ]}
      />
      <Divider />
      <FeatureRow
        product="IB"
        id={METRONOME_FEATURE_CONTROL}
        title="Contrôler son budget"
        imgSrc="/images/metronome/innovance-solutions-metronome-controler-son-budget.svg"
        imgWidth="757"
        imgHeight="474"
        items={[
          {
            desc: "Grace au module d’engagement, vous validez ou corrigez les demandes de dépenses proposées par les responsables de pôle/action. Vos volumes de dépenses sont immédiatement actualisés des nouveaux achats réalisés sans passer par la case comptable.",
            interest:
              "Le contrôle des écarts entre engagement et prévisionnel vous donne un vrai pouvoir d’arbitrage et de décision. Vous réalisez ainsi des économies et évitez les dépassements.",
          },
          {
            desc: "Vous disposez d’un contrôle très exact de la valorisation de vos actions : grâce à la ventilation automatique des couts salariaux sur ces actions ainsi qu’à la répartition automatique des charges indirectes et des subventions de fonctionnement.",
            interest:
              "Vous gagnez une image incroyablement juste de la répartition des couts de production et du fonctionnement. Cette image vous permet une analyse physiologique des missions de votre structure.",
          },
        ]}
      />
      <Divider />
      <FeatureRow
        direction="row-reverse"
        product="IB"
        id={METRONOME_FEATURE_REPORT}
        title="Présenter son budget"
        imgSrc="/images/budget/innovance-solutions-budget-rendre-compte-aux-financeurs.svg"
        imgWidth="757"
        imgHeight="474"
        items={[
          {
            desc: "Et puisqu’il faut tout justifier et justifier de tout, Métronome vous propose de créer vos propres modèles de présentation de compte à destination de votre gouvernance (CA, AG, Direction...). État, Région, Europe, chaque financeur ou tutelle a ses propres exigences en matière de rendu de compte. Nous vous livrons un tableau dynamique au format MS Excel pour faciliter l’exploitation de vos données (norme UNIDO, ratio DRAC...).",
            interest:
              "Gagnez en temps et en fiabilité lors de votre rendu de compte. Vous gardez le sourire et votre assurance lors de l’audit ou de la présentation annuelle.",
          },
        ]}
      />
    </Container>
  </StyledSectionWrapper>
);

export default Features;
