import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import {
  MdMusicNote,
  MdSupervisedUserCircle,
  MdImportExport,
  MdSecurity,
} from "react-icons/md";
import SectionTitle from "../../../components/layout/SectionTitle";
import Link from "../../../components/Link";
import ServiceItem from "../../../components/ServiceItem";
import {
  METRONOME_PRESENTATION_SECTION,
  QUALIOPI_HREF,
} from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

// Excel©

const Services = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper sectionId={METRONOME_PRESENTATION_SECTION}>
      <Container>
        <SectionTitle text="Une nouvelle solution..." variant="innoGrey" />
        <Grid container direction="column">
          <Grid container>
            <ServiceItem
              verticalDivier
              icon={MdMusicNote}
              color="deepPurple"
              title="... dédiée aux structures du spectacle vivant"
              text="Tous les lieux de diffusion et de création du spectacle vivant : scènes labellisées, scènes conventionnées d’intérêt national, Opéra, Pôle National de Cirque, Théâtres, salles de spectacle, associations (MJC, centre culturel…)."
            />
            <ServiceItem
              icon={MdSupervisedUserCircle}
              color="deepPurple"
              title="... pilotée par les acteurs décisionnaires"
              text="Administrateurs, secrétaire général, DAF, RAF , directeur de production, directeur adjoint, responsable de budget, contrôleur de gestion."
            />
          </Grid>
          {!isMobile && (
            <Divider
              className={clsx(classes.divider, { [classes.hide]: isMobile })}
              variant="fullWidth"
            />
          )}
          <Grid container>
            <ServiceItem
              verticalDivier
              icon={MdImportExport}
              color="deepPurple"
              title="... 100% ouverte à l’import/export de données"
              text="Import depuis les logiciels de comptabilité standard (Sage©, Ciel©, EBP©…), les logiciels de paie du spectacle (GHS sPAIEctacle©…). Export et exploitation des données sous Excel©."
            />
            <ServiceItem
              icon={MdSecurity}
              color="deepPurple"
              title="... sécurisée"
              text="Hébergement français et installé en France avec une double sauvegarde (quotidienne, bimensuelle, mensuelle)."
            />
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Services;
