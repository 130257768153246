import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "BudgetHero";

export const classes = {
  root: `${PREFIX}-root`,
  background: `${PREFIX}-background`,
  typo: `${PREFIX}-typo`,
  actions: `${PREFIX}-actions`,
  xsActions: `${PREFIX}-xsActions`,
  button: `${PREFIX}-button`,
};

export const StyledSectionWrapper = styled(SectionWrapper)<{
  isMobile: boolean;
}>(({ theme, isMobile }) => ({
  height: 520,

  [`& .${classes.background}`]: {
    backgroundImage: !isMobile
      ? "url('/images/metronome/innovance-solutions-metronome-spectacle-vivant.svg')"
      : "none",
    backgroundPosition: "85%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    backgroundAttachment: "local",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },

  [`& .${classes.typo}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.xsActions}`]: {
    flexDirection: "column",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.ibPurple.main,
    color: theme.palette.ibPurple.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.ibPurple.light,
      color: theme.palette.ibPurple.dark,
    },
  },
}));
