import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { ServiceItemProps } from "./props";
import { StyledGrid, classes } from "./styles";

const ServiceItem = (props: ServiceItemProps) => {
  const { title, text, verticalDivier = false, icon: Icon, color } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGrid
      container
      item
      sm={12}
      md={6}
      direction="column"
      alignItems="center"
      color={color || "blue"}
      className={clsx({
        [classes.verticalDivider]: verticalDivier && !isMobile,
      })}
    >
      {Icon ? (
        <Avatar className={clsx(classes.gutterBottom, classes.avatar)}>
          <Icon className={classes.icon} />
        </Avatar>
      ) : null}
      <Typography
        variant="h6"
        component="h3"
        align="center"
        className={clsx(classes.gutterBottom, classes.title)}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className={clsx(classes.text, classes.gutterBottom)}
      >
        {text}
      </Typography>
      {/* <Button
        onClick={() => handleNavigate("/#")}
        className={clsx(styles.button)}
        size="large"
        aria-label="En savoir plus"
      >
        En savoir plus
      </Button> */}
    </StyledGrid>
  );
};

export default ServiceItem;
