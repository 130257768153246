import { alpha } from "@mui/material/styles";
import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "Services";

export const classes = {
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
  hide: `${PREFIX}-hide`,
  link: `${PREFIX}-link`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.divider}`]: {
    color: alpha(theme.palette.innoGrey.main, 0.2),
  },

  [`& .${classes.hide}`]: {
    visibility: "hidden",
  },

  [`& .${classes.link}`]: {
    color: theme.palette.innoGrey.main,
    fontWeight: 600,
  },
}));
