import {
  BUDGET_PRICE_RANGES,
  METRONOME_PRICE_RANGES,
} from "../../../util/constants";
import { PriceRangeInfos } from "../../../util/types";

const findMatchingRange = (value: number) => (item: PriceRangeInfos) =>
  value >= item.start && value <= item.end;

export const getPriceRangeInfos = (value: number) => {
  let result: PriceRangeInfos[] = [];
  if (value < 500000) {
    // ne prendre que la première tranche, soit le forfait de base pour 5 salariés
    const baseRange: PriceRangeInfos = {
      ...METRONOME_PRICE_RANGES[0],
      count: value,
    };
    result = [baseRange];
    return result;
  }
  const range = METRONOME_PRICE_RANGES.find(
    findMatchingRange(value),
  ) as PriceRangeInfos;
  result = [
    {
      ...range,
      count: value,
    } as PriceRangeInfos,
  ];
  return result;
};
