import Grid from "@mui/material/Grid";
import styled from "../../../../util/styled";

const PREFIX = "AgendaFeatureRow";

export const classes = {
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  gutterBottom: `${PREFIX}-gutterBottom`,
  item: `${PREFIX}-item`,
};

export const StyledGrid = styled(Grid)<{ product?: "IA" | "IB" }>(
  ({ theme, product }) => ({
    margin: theme.spacing(5, 0),

    [`& .${classes.image}`]: {
      width: "100%",
      height: "auto",
      maxHeight: product ? 300 : undefined,
    },

    [`& .${classes.title}`]: {
      fontWeight: 600,
      color: !product
        ? theme.palette.innoGrey.main
        : product === "IA"
        ? theme.palette.iaPink.main
        : theme.palette.ibPurple.main,
    },

    [`& .${classes.gutterBottom}`]: {
      marginBottom: theme.spacing(3),
    },

    [`& .${classes.item}`]: {
      marginBottom: theme.spacing(2),
    },
  }),
);
