import React from "react";
import Customers from "../components/CustomerSection";
import Seo from "../components/Seo";
import MetronomeCarousel from "../views/Metronome/Carousel";
import Features from "../views/Metronome/Features";
import Hero from "../views/Metronome/Hero";
import MoreToKnow from "../views/Metronome/MoreToKnow";
import Presentation from "../views/Metronome/Presentation";
import MetronomePricings from "../views/Metronome/Pricing";
import { clients } from "../data";

const customers = clients.filter((c) => c.produits.includes("Métronome"));

const BudgetPage = () => {
  return (
    <React.Fragment>
      <Seo title="Innovance Métronome" />
      <Hero />
      <Presentation />
      <Features />
      <MetronomeCarousel />
      <MoreToKnow />
      <Customers customers={customers} />
      <MetronomePricings />
    </React.Fragment>
  );
};

export default BudgetPage;
