import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { FeatureRowProps } from "./props";
import { classes, StyledGrid } from "./styles";
import Alert from "@mui/material/Alert";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const FeatureRow = (props: FeatureRowProps) => {
  const {
    product,
    title,
    direction = "row",
    imgSrc,
    id,
    imgHeight,
    imgWidth,
    items,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGrid
      id={id}
      container
      title={title}
      direction={direction}
      justifyContent={isMobile ? "center" : "flex-start"}
      alignItems="center"
      product={product}
    >
      <Grid container item sm={5} alignItems="center">
        <img
          width={imgWidth}
          height={imgHeight}
          className={classes.image}
          src={imgSrc}
          alt={title}
        />
      </Grid>
      <Grid container item sm={12} md={7} direction="column">
        <Typography
          variant="h5"
          component="h3"
          className={clsx(classes.title, classes.gutterBottom)}
        >
          {title}
        </Typography>
        {items.map((i) => (
          <div className={classes.item}>
            <Typography gutterBottom variant="body1" component="div">
              {i.desc}
            </Typography>
            <Alert
              variant="outlined"
              severity="warning"
              icon={<LightbulbIcon />}
            >
              {i.interest}
            </Alert>
          </div>
        ))}
      </Grid>
    </StyledGrid>
  );
};

export default FeatureRow;
